import React from "react"
import { Box, Container } from "@theme-ui/components"
import ReferenceThumb from "../components/referenceThumb"

const ReferencesList = ({ references }) => {
  return (
    <Box>
      {references.map((reference, index) =>
        index === 0 ? (
          <Box sx={{ mt: [-5,-9], position: "relative", display: "block" }}>
            <Container variant="lg" sx={{pb:[6,6,7,7]}}>
              <ReferenceThumb
                reference={reference}
                key={reference.id}
                index={index}
              />
            </Container>
          </Box>
        ) : (
          <Box
            sx={{
              ":hover": {
                backgroundColor: "Grey",
              },
            }}
          >
            <Container
              sx={{
                py:[7,7,7,7],
                borderTop: index=== 1 && "1px solid",
                borderBottom: "1px solid",
                borderColor: "Grey",
              }}
            >
              <ReferenceThumb reference={reference} key={reference.id} />
            </Container>
          </Box>
        )
      )}
    </Box>
  )
}

export default ReferencesList
