import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getReferencePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink, OutboundLink } from "./link"
import PlayButtonRed from "../assets/images/play-button-red.svg"

const ReferenceThumb = ({ reference, small, index = null }) => {
  const [showIcon, setShowIcon] = useState()


  return (
    <Box
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      sx={{
        borderRadius: "sm",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <InboundLink
        to={getReferencePath(reference, reference.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Grid
          columns={[1, small ? 1 : ".4fr .6fr"]}
          gap={[3,index === 0 ? 0 : 6]}
          sx={{ minHeight: index === 0 ? "450px" : "280px" }}
        >
          <Box
            sx={{
              position: "relative",
              ".gatsby-image-wrapper": {
                height: "100%",
              },
            }}
          >
            <GatsbyImage image={reference.heroImage.gatsbyImageData} alt="" />
            {showIcon && index != 0 && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "-30px",
                  transform: "translateY(-50%)",
                }}
              >
                <PlayButtonRed />
              </Box>
            )}
          </Box>
          <Flex
            sx={{
              p: [3,4],
              py: [4,6],
              pt: [index === 0 ? 6 : 0],
              pb: [index === 0 ? 6 : 0],
              backgroundColor: index === 0 ? "primary" : "none",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Heading
              as="h2"
              variant="h4"
              sx={{ mb: 3, mt: 0, color: index !== 0 ? "dark" : "light" }}
            >
              {reference.title}
            </Heading>
            <Text
              dangerouslySetInnerHTML={{ __html: reference.subtitle }}
              as="div"
              variant="p"
              color={index !== 0 ? "dark" : "light"}
              sx={{
                p: { m: 0 },
              }}
            />
          </Flex>
        </Grid>
      </InboundLink>
    </Box>
  )
}

export default ReferenceThumb
