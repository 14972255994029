import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { getReferencesPath } from "../utils/path"
import ReferencesList from "./referencesList"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"

const References = ({ data: { page, references, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getReferencesPath(locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} />
      {references.nodes && <ReferencesList references={references.nodes} />}
    </Layout>
  )
}

export default References

export const query = graphql`
  query ReferencesPageQuery($id: String!, $locale: String!) {
    page: datoCmsReferencesPage(id: { eq: $id }) {
      ...ReferencesDetails
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    references: allDatoCmsReference(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
    ) {
      nodes {
        ...ReferenceDetails
        ...ReferenceAllSlugLocales
        ...ReferenceMeta
      }
    }

    site: datoCmsSite {
      locales
    }
  }

  fragment ReferencesDetails on DatoCmsReferencesPage {
    id
    locale
    title
    ...ReferencesAllSlugLocales
    model {
      apiKey
    }
  }

  fragment ReferencesAllSlugLocales on DatoCmsReferencesPage {
    _allSlugLocales {
      value
      locale
    }
  }
`
